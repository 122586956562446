<template>
  <order-detail-wrapper :status="orderData.exa_status">
    <section-title>注意事项</section-title>
    <notice :content="notice | decodeHTML"></notice>

    <section-title>换机证明</section-title>
    <img-display2to1 :src="orderData.tem_planes"></img-display2to1>

    <section-title>新机正面照</section-title>
    <img-display2to1 :src="orderData.tem_img"></img-display2to1>

    <section-title>更换信息</section-title>
    <van-cell title="姓名" :value="orderData.name"></van-cell>
    <van-cell title="手机号码" :value="orderData.phone"></van-cell>
    <van-cell title="旧IMEI号" :value="orderData.old_imei"></van-cell>
    <van-cell title="新IMEI号" :value="orderData.imei"></van-cell>
    <van-cell title="更换原因" :value="orderData.replace_reason"></van-cell>
    <van-cell title="险种类别" :value="orderData.instype_name"></van-cell>

    <section-title>订单信息</section-title>
    <notice>
        <div class="notice-item">
          <div class="notice-name">订单编号</div>
          <div class="notice-value">{{orderData.order_imei_sn||'-'}}</div>
        </div>
        <div class="notice-item">
          <div class="notice-name">提交时间</div>
          <div class="notice-value">{{orderData.add_time||'-'}}</div>
        </div>
<!--        <div class="notice-item">-->
<!--          <div class="notice-name">审核通过时间</div>-->
<!--          <div class="notice-value">{{orderData.exa_time||'-'}}</div>-->
<!--        </div>-->
<!--        <div class="notice-item">-->
<!--          <div class="notice-name">生效期限</div>-->
<!--          <div class="notice-value">{{orderData.exa_time||'-'}}</div>-->
<!--        </div>-->
        <div class="notice-item">
          <div class="notice-name">接待门店</div>
          <div class="notice-value">{{orderData.shop_name||'-'}}</div>
        </div>
        <div class="notice-item">
          <div class="notice-name">接待人员</div>
          <div class="notice-value">{{orderData.shop_member_name||'-'}}</div>
        </div>
        <div class="notice-item" v-if="orderData.exa_status===2">
          <div class="notice-name">未通过原因</div>
          <div class="notice-value">{{orderData.fail_exp||'-'}}</div>
        </div>
    </notice>

    <my-button class="edit-btn" v-if="orderData.exa_status===2" v-show="$route.query.canOperate!=='0'" @click="editOrder">编辑订单</my-button>
  </order-detail-wrapper>
</template>

<script>
import {getOrderImeiInfo} from "@/api/replaceIMEI";
import {article} from "@/api";

export default {
  name: "detail",
  props: {
    id:String
  },
  data() {
    return{
      orderData:{},
      notice:''
    }
  },
  mounted() {
    article(2).then(res=>{
      this.notice=res.data&&res.data.content
    })
    getOrderImeiInfo({order_imei_id:this.id}).then(res=>{
      this.orderData=res.data
    })
  },
  methods:{
    editOrder() {
      sessionStorage.setItem('data',JSON.stringify(this.orderData))
      this.$router.push({name:'ReplaceIMEIApply',params:{phoneType:0}})
    }
  }
}
</script>

<style scoped>
.notice-item {
  display: flex;
  line-height: 60px;
}
.notice-name {
  color: #666;
  min-width: 220px;
  white-space: nowrap;
}
.notice-value{
  word-break:break-all;
}
.edit-btn {
  width: calc(100vw - 150px);
  margin: 30px auto;
}
</style>
